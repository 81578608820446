import { createUpdatesThunk } from 'reducers/update-slice/reducers/createUpdateReducer';
import { fetchUpdatesThunk } from 'reducers/update-slice/reducers/fetchUpdatesReducer';
import { updateUpdatesThunk } from 'reducers/update-slice/reducers/updateUpdateReducer';
import { deleteUpdatesThunk } from 'reducers/update-slice/reducers/deleteUpdatesReducer';
import { postFileThunk } from 'reducers/file-slice/reducers/postFileReducer';
import { AppDispatch } from 'reducers/store';
import { Attributes, update } from 'reducers/update-slice/type';
import { deleteFileThunk } from 'reducers/file-slice/reducers/deleteFileReducer';
import { fetchGoalThunk } from 'reducers/goal-slice/reducers/fetchGoalReducer';
import { Goal } from 'reducers/goal-slice/type';
import { FetchGoalResponse } from 'api/type';

export const postNewUpdate = (
  dispatch: AppDispatch,
  newUpdate: Attributes,
  goalId: string,
  onCancel: () => void,
  file?: File | null,
): void => {
  if (file) {
    dispatch(postFileThunk({ file, goalId })).then(fileData => {
      newUpdate = { ...newUpdate, file_id: +(fileData.payload as string) };
      dispatch(createUpdatesThunk(newUpdate)).then(() => {
        dispatchFetch(dispatch, goalId, onCancel);
      });
    });
  } else {
    dispatch(createUpdatesThunk(newUpdate)).then(() => {
      dispatchFetch(dispatch, goalId, onCancel);
    });
  }
};

export const putUpdate = (
  dispatch: AppDispatch,
  newUpdate: Attributes,
  goalId: string,
  onCancel: () => void,
  editedItem: update,
  file?: File | null,
): void => {
  const payload = { ...editedItem };
  payload.attributes = newUpdate;
  if (file) {
    dispatch(postFileThunk({ file, goalId })).then(fileData => {
      payload.attributes.file_id = +(fileData.payload as string);
      dispatch(updateUpdatesThunk(payload)).then(() => {
        if (editedItem.relationships.file.data) {
          dispatch(deleteFileThunk({ id: editedItem.relationships.file.data.id })).then(() => {
            dispatchFetch(dispatch, goalId, onCancel);
          });
        } else {
          dispatchFetch(dispatch, goalId, onCancel);
        }
      });
    });
  } else {
    dispatch(updateUpdatesThunk(payload)).then(() => {
      dispatchFetch(dispatch, goalId, onCancel);
    });
  }
};

export const deleteUpdate = (dispatch: AppDispatch, update: update, goalId: string, onCancel: () => void): void => {
  if (update.relationships.file.data) {
    dispatch(deleteUpdatesThunk(update)).then(() => {
      dispatch(deleteFileThunk({ id: update.relationships.file.data.id })).then(() => {
        dispatchFetch(dispatch, goalId, onCancel);
      });
    });
  } else {
    dispatch(deleteUpdatesThunk(update)).then(() => {
      dispatchFetch(dispatch, goalId, onCancel);
    });
  }
};

const dispatchFetch = (dispatch: AppDispatch, goalId: string, onCancel: () => void) => {
  dispatch(fetchGoalThunk({ id: goalId })).then(res => {
    const response = res.payload as FetchGoalResponse;
    dispatch(fetchUpdatesThunk({ page: 1, goal: (response.data as unknown) as Goal })).then(() => {
      onCancel();
    });
  });
};
