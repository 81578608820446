import * as React from "react";
import bn from "helper/bemnames";
import { Goal } from "reducers/goal-slice/type";
import { SuccessIcon, BoxArrowUpRight } from "components/core/icons/Icons";
import Button from "components/core/button/Button";
import { getGoalHubPage } from "helper/common";

const bem = bn.create("goal-confirmation");
interface Props {
  goal?: Goal;
  instanceName: string;
}
const GoalConfirmation: React.FC<Props> = ({ goal, instanceName }: Props) => {
  return (
    <div className={bem.b()}>
      <div className={bem.e("success-image")}>
        <SuccessIcon width={80} height={80} />
      </div>
      <span className={bem.e("text")}>Goal &apos;{goal?.attributes.title}&apos; has been successfully created.</span>
      <div className={bem.e("link")}>
        <Button
          size="small"
          disabled={!goal}
          onClick={() => {
            if (goal) {
              window.open(getGoalHubPage(instanceName, goal?.id));
            }
          }}
          data-testid="confirmation-open-goal"
          className={bem.e("btn-link")}
          icon={<BoxArrowUpRight />}
          content="Open in Cascade"
          text
        />
      </div>
    </div>
  );
};
export default GoalConfirmation;
