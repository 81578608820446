import * as React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import bn from "helper/bemnames";
const bem = bn.create("tooltip");

interface Props {
  trigger?: React.Component | React.ReactNode;
  message?: React.Component | string | React.ReactNode | Element;
}
const Tooltip: React.FC<Props> = (props: Props) => {
  const { message = "", trigger } = props;
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  return (
    <div className={bem.b()}>
      <div className={bem.e("trigger")} ref={setTriggerRef}>
        {trigger}
      </div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: bem.e("tooltip-container") })}>
          <div {...getArrowProps({ className: bem.e("tooltip-arrow") })} />
          {message}
        </div>
      )}
    </div>
  );
};
export default Tooltip;
