import * as React from "react";
import { GoalProgressStatus } from "reducers/goal-slice/type";
import bn from "helper/bemnames";
import classNames from "classnames";
const bem = bn.create("goal-status-text");
interface Props {
  status: GoalProgressStatus;
}
export const CLASS_COLORS = {
  "NOT-STARTED": "not-start",
  OVERDUE: "overdue",
  "ON-TRACK": "on-track",
  BEHIND: "behind",
  COMPLETE: "complete",
  "WITHIN-THRESHOLD": "within-threshold",
  MANUAL: "manual",
};
const TITLES = {
  "NOT-STARTED": "Not Start",
  OVERDUE: "Overdue",
  "ON-TRACK": "On track",
  BEHIND: "Behind",
  COMPLETE: "Completed",
  "WITHIN-THRESHOLD": "Within threshold",
  MANUAL: "Manual",
};
const StatusText: React.FunctionComponent<Props> = ({ status }: Props) => {
  return <span className={classNames(bem.b(), bem.m(CLASS_COLORS[status]))}>{TITLES[status]}</span>;
};
export default StatusText;
