import { combineReducers } from "@reduxjs/toolkit";

import { reducer as auth } from "reducers/auth-slice";
import { reducer as goal } from "reducers/goal-slice";
import { reducer as template } from "reducers/template-slice";
import { reducer as weight } from "reducers/weight-slice";
import { reducer as user } from "reducers/user-slice";
import { reducer as plan } from "reducers/plan-slice";
import { reducer as update } from 'reducers/update-slice';
import { reducer as config } from 'reducers/config-slice';
import { reducer as file } from 'reducers/file-slice';

const rootReducer = combineReducers({
  auth,
  goal,
  template,
  weight,
  user,
  plan,
  update,
  config,
  file
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
