import { BASE_URL } from "config/enviroment";
import { TokenState } from "reducers/auth-slice/type";
import { apiClient } from "./client";
import { RefreshTokenProps, RefreshTokenResponse, GetTokenProps, FetchProfileResponse } from "./type";

export const getRefreshToken = async (payload: RefreshTokenProps): Promise<{ data: RefreshTokenResponse }> => {
  const url = "/api/auth/refresh-token";
  const { data } = await apiClient.post(url, payload, { baseURL: BASE_URL });
  return data;
};

export const getAccessToken = async (payload: GetTokenProps): Promise<{ data: TokenState }> => {
  const url = "/api/auth/token";
  const { data } = await apiClient.post(url, payload, { baseURL: BASE_URL });
  return data;
};

export const fetchProfileApi = async (): Promise<FetchProfileResponse> => {
  const url = `/users/self?fields[role]=lft,rgt,org_unit_id,privileges,roleFamily,role_family_id&include=role.privileges,role.roleFamily,role.roleFamily.roles,privileges&fields[privilege]=privilege`;
  const { data } = await apiClient.get<FetchProfileResponse>(url);
  return data;
};
