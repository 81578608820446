import { createSelector } from "@reduxjs/toolkit";
import { Plan, PlanIncluded } from "reducers/plan-slice/type";
import { RootState } from "reducers/rootReducer";
import { Item as ItemDropdown } from "components/core/dropdown/type";
import { Goal } from "reducers/goal-slice/type";
export const selectPlans = (state: RootState): Plan[] => state.plan.plans;

export const selectPlansForDropdown = createSelector(
  (state: RootState) => state.plan.plans,
  (state: RootState) => state.plan.included,
  (plans, included): ItemDropdown<Plan>[] => {
    return plans.map((plan: Plan) => {
      const focusAreasIds = plan.relationships.focusAreas.data.map((item: PlanIncluded) => item.id);
      return {
        header: plan.attributes.name,
        data: {
          ...plan,
          relationships: {
            focusAreas: {
              data: included.filter((item: PlanIncluded) => focusAreasIds.includes(item.id)),
            },
          },
        },
      };
    });
  }
);

export const selectGoalsByPlanForDropdown = createSelector(
  (state: RootState) => state.plan.goalsByPlan || [],
  (goalsByPlan): ItemDropdown<Goal>[] =>
    goalsByPlan.map((goal: Goal) => ({
      header: goal.attributes.title,
      data: goal,
    }))
);

export const selectError = (state: RootState): string | undefined | null => state.plan.error;

export const selectIsLoading = (state: RootState): boolean => state.plan.isLoading;
