import * as React from "react";
import { LockIcon } from "components/core/icons/Icons";
import { DialogFooter, Flex } from "@fluentui/react-northstar";
import BaseModal, { BaseModalProps } from "components/modals/base-modal/Base";
import Button from "components/core/button/Button";
import Input from "components/core/input/Input";
import bn from "helper/bemnames";
const bem = bn.create("sign-in-modal");

type Props = BaseModalProps & {
  instanceName: string;
  errorMessage: string;
  setInstanceName: (value: string) => void;
  setErrorMessage: (value: string) => void;
};

const SignInModal: React.FC<Props> = (props: Props) => {
  const { instanceName, setInstanceName, className, errorMessage, setErrorMessage, ...rest } = props;
  return (
    <BaseModal
      {...rest}
      closeOnOutsideClick={false}
      className={bem.b([className])}
      footer={
        <DialogFooter className={bem.e("footer")}>
          <Flex column hAlign="center">
            <Button
              key="submit"
              primary
              buttonColor="primary"
              disabled={!instanceName}
              data-testid="btn-submit-sign-in"
              content="Continue"
              className={bem.e("btn-submit")}
              onClick={props.onConfirm}
            />
          </Flex>
        </DialogFooter>
      }
      content={
        <div className={bem.e("modal-content")}>
          <div className={bem.e("lock")}>
            <LockIcon className={bem.e("lock-icon")} />
          </div>
          <div className={bem.e("content")}>
            <h2 className={bem.e("title")}>Login to Cascade</h2>
            <p className={bem.e("description")}>Enter your instance name to start</p>
            <div className={bem.e("sub-des")}>
              <p>Usually this will be in the form of</p>
              <p className={bem.e("instance-name-example")}>‘<b>yourcompanyname</b><span>.executestrategy.net’</span></p>.
            </div>
            <div className={bem.e("input-container")}>
              <div className={bem.e("input-content")}>
                <Input
                  className={bem.e("input")}
                  placeholder=""
                  value={instanceName}
                  onChange={(_event, data) => {
                    setErrorMessage("");
                    setInstanceName(data?.value ?? "");
                  }}
                  onKeyPress={(event) => {
                    if (event.charCode === 13 && props.onConfirm) {
                      props.onConfirm(event);
                    }
                  }}
                  error={!!errorMessage}
                />
                {errorMessage && <span className={bem.e("error-message")}>{errorMessage}</span>}
              </div>
              <span className={bem.e("domain")}>.executestrategy.net</span>
            </div>
          </div>
        </div>
      }
    ></BaseModal>
  );
};
export default SignInModal;
