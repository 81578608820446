import * as React from "react";
import Type from "components/goals/type/Type";
import Owner from "components/goals/owner/Owner";
import { Goal, GoalProgressStatus, GoalType } from "reducers/goal-slice/type";
import bn from "helper/bemnames";
import { getManualStatus } from "helper/goal";
import { path } from "ramda";
import StatusText from "components/goals/status-text/StatusText";
import classNames from "classnames";
const bem = bn.create("goals");
interface Props {
  goals: Goal[];
  fetchGoal: (id: string | number) => void;
}
const GoalRows = (props: Props): JSX.Element => {
  const { goals, fetchGoal } = props;
  const rows = goals.map(({ attributes, relationships, id }: Goal, index: number) => {
    const owners = Array.isArray(relationships.owners?.data) ? relationships.owners?.data : [];
    const isManualStatus = attributes.settings && attributes.settings?.tracking.manualStatus;
    const status = (isManualStatus ? getManualStatus(attributes) : attributes.progress_status) as GoalProgressStatus;

    return (
      <div
        className={bem.e("goal-row")}
        key={`goal-row-${id}`}
        data-testid={`goal-row-${index}`}
        onClick={() => {
          fetchGoal(id);
        }}
      >
        <span className={bem.e("title")}>{attributes.title}</span>
        <ul className={bem.e("goal-attributes")}>
          <li>
            <Type type={path(["entityTemplate", "data", "attributes", "name"], relationships) as GoalType} />
          </li>
          <li
            className={classNames(bem.e("attribute-middle"), {
              [bem.e("border-right")]: owners.length > 0,
            })}
          >
            <StatusText status={status} />
          </li>
          {owners.length > 0 ? (
            <li data-testid={`owner-${index}`}>
              <Owner owner={owners[0]} isText />
            </li>
          ) : null}
        </ul>
      </div>
    );
  });
  return <div className={bem.e("goals")}>{rows}</div>;
};
export default GoalRows;
