/* eslint-disable @typescript-eslint/no-var-requires */
import { TokenState } from "reducers/auth-slice/type";
import { FormGoalProps as FormGoalApiProps } from "api/type";
// import * as moment from "moment";
import { GoalFormProps } from "components/forms/goal/type";
import { includes, split, trim } from "lodash";
const moment = require("moment");

export function roundedNumber(round: number, number: number): number {
  return Number(number.toFixed(round));
}
export function getGoalHubPage(instanceName: string, goalId: string | number): string {
  return `https://${instanceName}.executestrategy.net/user/goals/${goalId}`;
}
export function formatTokenState(data: {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  instance: string;
}): TokenState {
  return {
    accessToken: data.access_token,
    expiresIn: data.expires_in,
    tokenType: data.token_type,
    refreshToken: data.refresh_token,
    instance: data.instance,
  };
}
export function formatFormGoalProps(data: GoalFormProps): FormGoalApiProps {
  const relationships = {
    entity_template: {
      data: {
        id: data.entityTemplate && data.entityTemplate?.data ? data.entityTemplate?.data.id : "",
        type: "entity_template",
      },
    },
    weight: {
      data: {
        id: data.weight && data.weight?.data ? data.weight?.data.id : "",
        type: "weight",
      },
    },
    owners: {
      data: [
        {
          id:
            data.owner?.data?.relationships?.role?.data && data.owner?.data?.relationships?.role?.data.id
              ? data.owner?.data?.relationships?.role?.data.id
              : "",
          type: "role",
        },
      ],
    },
    aligned_focus_areas: {
      data:
        data.aligned_to && data.aligned_to.data?.type === "focusArea"
          ? [
              {
                id: data.aligned_to.data.id,
                type: "focus_area",
              },
            ]
          : [],
    },
    aligned_parent_goals: {
      data:
        data.aligned_to && data.aligned_to.data?.type === "goal"
          ? [
              {
                id: data.aligned_to.data.id,
                type: "goal",
              },
            ]
          : [],
    },
    aligned_parent_goals_appro: {
      data:
        data.aligned_to && data.aligned_to.data?.type === "goal"
          ? [
              {
                id: data.aligned_to.data.id,
                type: "goal",
              },
            ]
          : [],
    },
  };
  const attributesParams: { progress?: string | number } = {};
  if (data.id) {
    attributesParams.progress = data.progress ? Number(data.progress) : 0;
  }
  const formatted = {
    type: "goal",
    attributes: {
      ...attributesParams,
      title: data.title,
      start_time: `${moment(data.start_time).startOf('day').toISOString()}`,
      end_time: `${moment(data.end_time).endOf('day').toISOString()}`,
      description: data.description,
      settings: data.settings,
      custom_attributes: data.custom_attributes,
    },
    relationships,
  };
  if (!data.custom_attributes || data.custom_attributes == "" || Object.keys(data.custom_attributes).length <= 0) {
    delete formatted.attributes.custom_attributes;
  }
  if (!data.settings || data.settings == "" || Object.keys(data.settings).length <= 0) {
    delete formatted.attributes.settings;
  } else {
    formatted.attributes.settings = JSON.stringify(data.settings);
  }
  return {
    data: formatted,
  };
}

export function convertObjectToBase64(obj: Record<string, unknown>): string {
  const objJsonStr = JSON.stringify(obj);
  return btoa(objJsonStr);
}

export function formatNumber(target: string | number): number {
  return Number(target);
}

export function detectPermissionFromResponseHeaders(headers: { "access-control-allow-methods": string }): boolean {
  const methods = headers["access-control-allow-methods"];
  // "POST, GET, OPTIONS, PUT, PATCH, DELETE"
  return includes(methods, "PATCH");
}

export function getDescriptionDataFromOutlook(): Promise<string> {
  return new Promise((resolve) => {
    const title = "Added by Cascade from Outlook";
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, (asyncResult: Office.AsyncResult<string>) => {
      const emailBody = asyncResult.value;
      const from = `${Office.context.mailbox.item.from.displayName} ${Office.context.mailbox.item.from.emailAddress}`;
      const subject = Office.context.mailbox.item.subject;
      const emailContent = `${title}\r\nFrom: ${from}\r\n${subject}\r\n${emailBody}`;
      resolve(emailContent);
    });
  });
}
export function counter(string: string): number {
  const array = split(string, "");
  return array.reduce(function (count, item) {
    if (trim(item).length > 0) {
      count++;
    }
    return count;
  }, 0);
}
export function extractContent(s: string): string {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}
