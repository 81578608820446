import { createSlice } from '@reduxjs/toolkit';
import { fetchFileReducer } from './reducers/fetchFileReducer';
import { postFileReducer } from './reducers/postFileReducer';
import { deleteFileReducer } from './reducers/deleteFileReducer';
import { FileState } from './type';

const initialState: FileState = { file: {}, isLoading: false };

const { reducer, actions } = createSlice({
  name: 'file',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchFileReducer(builder);
    postFileReducer(builder);
    deleteFileReducer(builder);
  },
});
export { reducer, actions as planAction };
