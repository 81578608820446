/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder, Dispatch } from "@reduxjs/toolkit";

import { TemplateState } from "reducers/template-slice/type";
import { ValidationErrors } from "type/error";
import { FetchTemplatesProps, FetchTemplatesResponse } from "api/type";
import { fetchTemplatesApi } from "api/template";
import { StatusCode } from "type/error";
import { convertObjectToBase64 } from "helper/common";

export const fetchTemplatesThunk = createAsyncThunk<
  FetchTemplatesResponse,
  FetchTemplatesProps,
  {
    rejectValue: ValidationErrors;
    dispatch: Dispatch;
  }
>("template/fetchTemplates", async (option) => {
  try {
    const filter = convertObjectToBase64({
      operator: "and",
      value: [
        {
          field: "is_enabled",
          operator: "=",
          value: true,
        },
        {
          field: "is_archived",
          operator: "=",
          value: false,
        },
        {
          field: 'entity_type',
          operator: '=',
          value: {
            operator: 'or',
            value: ['goal, update'],
          },
        },
      ],
    });
    const response = await fetchTemplatesApi({ ...option, filter, include: 'entityTemplateAttributes' });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchTemplatesReducer = (builder: ActionReducerMapBuilder<TemplateState>): void => {
  builder.addCase(fetchTemplatesThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.templates = payload.data;
    state.pagination = payload.meta.pagination;
    if (payload.included) {
      const attributes = [...payload.included];
      state.attributesIncluded = attributes;
    }
  });
  builder.addCase(fetchTemplatesThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchTemplatesThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};
