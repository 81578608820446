import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as ProviderTheme, teamsTheme } from "@fluentui/react-northstar";

import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "reducers/store";
import GoalsScreen from "taskpane/profile/goal-list-screen/GoalsScreen";
import GoalFormScreen from "taskpane/profile/goal-form/GoalFormScreen";
import WelcomeScreen from "./authentication/WelcomeScreen";
import AuthScreen from "./authentication/AuthScreen";
import AuthorizeWindow from "./authentication/AuthorizeWindow";

import PrivateAuthenticationRoute from "./navigation/PrivateAuthenticationRoute";
import { routes } from "./navigation/Routes";
import LayoutBase from "taskpane/layout/Layout";
import AxiosInterceptors from "api/client";
import { hot } from "react-hot-loader/root";

AxiosInterceptors.setup(store);
interface Props {}

const App: React.FC<Props> = () => {
  return (
    <ProviderTheme theme={teamsTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <LayoutBase>
              <Switch>
                <Route exact path={routes.SIGN_IN} component={WelcomeScreen} />
                <Route exact path={routes.AUTH} component={AuthScreen} />
                <Route exact path={routes.AUTH_CALLBACK} component={AuthorizeWindow} />
                <PrivateAuthenticationRoute exact path={routes.CREATE_GOAL} component={GoalFormScreen} />
                <PrivateAuthenticationRoute exact path={routes.GOALS} component={GoalsScreen} />
                <PrivateAuthenticationRoute exact path={routes.GOAL} component={GoalFormScreen} />
              </Switch>
            </LayoutBase>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ProviderTheme>
  );
};

export default hot(App);
