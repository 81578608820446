import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-hot-loader";
import App from "./App";
import * as React from "react";
import * as ReactDOM from "react-dom";

export const render = (Component: any): any => {
  ReactDOM.render(<Component />, document.getElementById("container"));
};

/* Render application after Office initializes */
Office.onReady(() => {
  render(App);
});
