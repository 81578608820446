import * as React from "react";
import Dropdown from "components/core/dropdown/Dropdown";
import { DropdownProps } from "@fluentui/react-northstar";
import { AcceptIcon, CloseIcon } from "components/core/icons/Icons";
import FormMessage from "components/core/form-message/FormMessage";
import bn from "helper/bemnames";
import { useOnClickOutside } from "hook/useOnClickOutside";

import classNames from "classnames";
const bem = bn.create("form-field");
type Props = DropdownProps & {
  isShowActions?: boolean;
  dirty?: boolean;
  errorMessage?: string | string[] | any;
  actionsProps?: {
    handleSave?: () => void;
    handleBack?: () => void;
  };
};
const FormDropdown: React.FC<Props> = (props: Props) => {
  const { error, errorMessage, actionsProps, dirty, isShowActions, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!dirty) return;
    actionsProps?.handleSave && actionsProps?.handleSave();
  });
  return (
    <div className={bem.b()} ref={ref}>
      <Dropdown {...rest} />
      <div className={bem.e("bottom")}>
        {error && <FormMessage data-testid="error" error content={errorMessage} />}
        {isShowActions && (
          <div className={bem.e("actions")} data-testid="actions">
            <span className={classNames(bem.e("accept"), bem.e("button"))} onClick={actionsProps?.handleSave}>
              <AcceptIcon />
            </span>
            <span className={classNames(bem.e("close"), bem.e("button"))} onClick={actionsProps?.handleBack}>
              <CloseIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default FormDropdown;
