import { createSlice } from "@reduxjs/toolkit";
import { fetchGoalsReducer } from "./reducers/fetchGoalsReducer";
import { fetchGoalReducer } from "./reducers/fetchGoalReducer";
import { createGoalReducer } from "./reducers/createGoalReducer";
import { updateGoalReducer } from "./reducers/updateGoalReducer";
import { updateGoalIdsReducer } from "./reducers/updateGoalIdsReducer";
import { authAction } from "reducers/auth-slice";
import { GoalState } from "./type";
export const initialState: GoalState = {
  goals: [],
  isLoading: false,
  included: [],
  goal: undefined,
  includedDetail: [],
  goalIds: [],
};

const { reducer, actions } = createSlice({
  name: "goal",
  initialState,
  reducers: {
    updateGoalIds: updateGoalIdsReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(authAction.signOutUser, () => initialState);
    fetchGoalsReducer(builder);
    fetchGoalReducer(builder);
    createGoalReducer(builder);
    updateGoalReducer(builder);
  },
});
export { reducer, actions as goalAction };
