import * as React from "react";
import { SystemErrorIcon } from "components/core/icons/Icons";
import bn from "helper/bemnames";
const bem = bn.create("system-error");

interface Props {
  title?: string;
  message?: string;
}
const SystemError: React.FC<Props> = (props: Props) => {
  const {
    title = "Something went wrong",
    message = "Looks like there is a glitch in our system. You can refresh or start over if that doesn't seem to fix the issue",
  } = props;
  return (
    <div className={bem.b()}>
      <div className={bem.e("image")}>
        <SystemErrorIcon />
      </div>
      <h2 className={bem.e("title")}>{title}</h2>
      <p className={bem.e("description")}>{message}</p>
    </div>
  );
};
export default SystemError;
