/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder, Dispatch } from "@reduxjs/toolkit";

import { WeightState } from "reducers/weight-slice/type";
import { ValidationErrors } from "type/error";
import { FetchWeightsProps, FetchWeightsResponse } from "api/type";
import { fetchWeightsApi } from "api/weight";
import { StatusCode } from "type/error";

export const fetchWeightsThunk = createAsyncThunk<
  FetchWeightsResponse,
  FetchWeightsProps,
  {
    rejectValue: ValidationErrors;
    dispatch: Dispatch;
  }
>("weight/fetchWeights", async (option) => {
  try {
    const response = await fetchWeightsApi({ ...option });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchWeightsReducer = (builder: ActionReducerMapBuilder<WeightState>): void => {
  builder.addCase(fetchWeightsThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.weights = payload.data;
    state.pagination = payload.meta.pagination;
  });
  builder.addCase(fetchWeightsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchWeightsThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};
