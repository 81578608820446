import * as React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import bn from "helper/bemnames";
import classNames from "classnames";
const bem = bn.create("textarea");
const TextArea: React.FC<TextareaAutosizeProps> = (props: TextareaAutosizeProps) => {
  const { className, ...rest } = props;
  return <TextareaAutosize {...rest} className={classNames(bem.b(), className)} />;
};
export default TextArea;
