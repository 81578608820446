import * as React from "react";
import { Input, InputProps } from "@fluentui/react-northstar";
import bn from "helper/bemnames";
import classNames from "classnames";
const bem = bn.create("input");
const InputText: React.FC<InputProps> = (props: InputProps) => {
  const { readOnly } = props;
  if (readOnly) {
    return <span className={classNames(bem.b(), bem.m("read-only"))}>{props.value}</span>;
  }
  return <Input {...props} className={bem.b()} />;
};
export default InputText;
