import { CLIENT_ID } from "config/enviroment";
import { REDIRECT_URI, SCOPES } from "constant/url";

export const prepareAuthUri = (instanceName: string): string =>
  `https://${instanceName}.executestrategy.net/user/authorize?response_type=code&state=${instanceName}&client_id=${CLIENT_ID}&scope=${SCOPES}&redirect_uri=${REDIRECT_URI}`;

/**
 * Generate the instance URL for the passed instance name.
 *
 * @param rawInstanceName
 */
export const prepareInstanceURL = (rawInstanceName: string): string => `https://${rawInstanceName}.executestrategy.net`;

/**
 * Get the base public API endpoint for the passed instance name. If the instance name contains more that one segment
 * (identified by splitting at '.'), then the first segment is treated as the Cascade instance name, and the second
 * segment is treated as the environment. The following pattern will be applied:
 * https://{instanceName}.api.[environment.]executestrategy.net/
 *
 * @param rawInstanceName
 * @param instanceRegion
 */
export const prepareAPIBaseUrl = (rawInstanceName: string, instanceRegion: string | undefined = undefined): string => {
  const [instanceName, environment] = rawInstanceName.split(".");
  const baseDomain =
    (instanceRegion ? `${instanceRegion}.` : "") + (environment ? `${environment}.` : "") + "executestrategy.net";

  return `https://${instanceName}.api.${baseDomain}`;
};

export const prepareAuthorizationToken = (accessToken: string, tokenType = "Bearer"): string =>
  `${tokenType} ${accessToken}`;
