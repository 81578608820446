/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder, Dispatch } from "@reduxjs/toolkit";

import { GoalState } from "reducers/goal-slice/type";
import { ValidationErrors } from "type/error";
import { FetchGoalsProps, FetchGoalsResponse } from "api/type";
import { fetchGoalsApi } from "api/goal";
import { PAGE_SIZE_NUMBER } from "config/api";
import { StatusCode } from "type/error";
import { convertObjectToBase64 } from "helper/common";
import { selectGoalIds } from "reducers/goal-slice/selector";

import { RootState } from "reducers/rootReducer";
export const fetchGoalsThunk = createAsyncThunk<
  FetchGoalsResponse,
  FetchGoalsProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
    dispatch: Dispatch;
  }
>("goal/fetchGoals", async (option, { getState }) => {
  try {
    const state: RootState = getState();
    const goalIds = selectGoalIds(state);
    const filter = convertObjectToBase64({
      field: "id",
      operator: "in",
      value: goalIds,
    });

    const response = await fetchGoalsApi({ ...option, size: PAGE_SIZE_NUMBER, filter, include: "include=owners.user,aligment_parent_goals_appro.id" });

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchGoalsReducer = (builder: ActionReducerMapBuilder<GoalState>): void => {
  builder.addCase(fetchGoalsThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.goals = payload.data;
    state.pagination = payload.meta.pagination;
    state.included = payload.included;
  });
  builder.addCase(fetchGoalsThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchGoalsThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};
