import * as React from "react";
import { SettingIcon, LogoutIcon, HelpIcon } from "components/core/icons/Icons";
import bn from "helper/bemnames";
import classNames from "classnames";
import { useOnClickOutside } from "hook/useOnClickOutside";

const bem = bn.create("setting-contextual");
type Props = {
  userName: string;
  onGetHelpClick: () => void;
  onLogOutClick: () => void;
};

export const SettingContextualMenu: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className={bem.b()} ref={ref}>
      <div className={bem.e("setting-content")}>
        <div className={bem.e("setting")} onClick={() => setIsOpen(!isOpen)}>
          <SettingIcon />
        </div>
        <ul
          className={classNames(bem.e("menu"), {
            [bem.e("menu-open")]: isOpen,
          })}
        >
          <li>
            <span className={bem.e("user-name")}>{props.userName}</span>
          </li>
          <li>
            <a
              className={bem.e("link")}
              onClick={(e) => {
                e.preventDefault();
                props.onGetHelpClick();
                setIsOpen(false);
              }}
            >
              <span className={bem.e("icon")}>
                <HelpIcon />
              </span>
              <span className={bem.e("menu-text")}>Get Help</span>
            </a>
          </li>
          <li>
            <a
              className={bem.e("link")}
              onClick={(e) => {
                e.preventDefault();
                props.onLogOutClick();
                setIsOpen(false);
              }}
            >
              <span className={bem.e("icon")}>
                <LogoutIcon />
              </span>
              <span className={bem.e("menu-text")}>Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
