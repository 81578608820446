/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder, Dispatch } from "@reduxjs/toolkit";

import { GoalState } from "reducers/goal-slice/type";
import { ValidationErrors } from "type/error";
import { fetchGoalApi } from "api/goal";
import { FetchGoalProps, FetchGoalResponse } from "api/type";
import { StatusCode } from "type/error";

export const fetchGoalThunk = createAsyncThunk<
  FetchGoalResponse,
  FetchGoalProps,
  {
    rejectValue: ValidationErrors;
    dispatch: Dispatch;
  }
>("goal/fetchGoal", async (option) => {
  try {
    const response = await fetchGoalApi(option);

    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchGoalReducer = (builder: ActionReducerMapBuilder<GoalState>): void => {
  builder.addCase(fetchGoalThunk.fulfilled, (state, { payload }) => {
    state.error = undefined;
    state.goal = payload.data;
    state.includedDetail = payload.included;
    state.isLoadingGoal = false;
  });
  builder.addCase(fetchGoalThunk.pending, (state) => {
    state.error = undefined;
    state.isLoadingGoal = true;
  });
  builder.addCase(fetchGoalThunk.rejected, (state, action) => {
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
    state.isLoadingGoal = false;
  });
};
