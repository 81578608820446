import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersReducer } from "./reducers/fetchUsersReducer";

import { UserState } from "./type";
const initialState: UserState = { users: [], isLoading: false };

const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchUsersReducer(builder);
  },
});
export { reducer, actions as userAction };
