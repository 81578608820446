import classNames from "classnames";
type BemFunction = {
  b: (more?: (string | undefined)[]) => string;
  e: (className: string, more?: (string | undefined)[]) => string;
  m: (className: string, more?: (string | undefined)[]) => string;
};
interface CreateBemFunction {
  create: (blockName: string) => BemFunction;
}
export const createBEM = (namespace: string): CreateBemFunction => {
  return {
    create: (blockName): BemFunction => {
      let block = blockName;

      block = `${namespace}-${blockName}`;

      return {
        b: (more) => {
          return classNames(block, more);
        },
        e: (className, more) => {
          return classNames(`${block}__${className}`, more);
        },
        m: (className, more) => {
          return classNames(`${block}--${className}`, more);
        },
      };
    },
  };
};

export const bemNames = createBEM("ca");

export default bemNames;
