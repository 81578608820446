import * as React from "react";
import BaseModal, { BaseModalProps } from "components/modals/base-modal/Base";
import GoalForm from "components/forms/goal/Goal";
import { getGoalHubPage } from "helper/common";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Feed from 'components/feed/Feed';
import PageTitle from "components/page-title/PageTitle";
import { ChevronStartIcon, BoxArrowUpRight, CopyIcon } from "components/core/icons/Icons";
import { routes } from "taskpane/navigation/Routes";
import Button from "components/core/button/Button";
import Tooltip from "components/tooltip/Tooltip";
import GoalConfirmation from "components/goals/confirmation/Confirmation";
import bn from "helper/bemnames";
import { ActionMode, GoalProps } from "components/forms/goal/type";
import { delay, omit } from "lodash";
import { Goal } from "reducers/goal-slice/type";
const bem = bn.create("goal-modal");
export type GoalModalProps = BaseModalProps & {
  isShowConfirmation?: boolean;
  setIsShowConfirmation?: (status: boolean) => void;
};
export interface Props {
  modalProps: GoalModalProps;
  formProps: GoalProps;
}
function HeaderEdit({ goal, instanceName }: { goal: Goal; instanceName: string }) {
  const [isCopied, setIsCopied] = React.useState(false);
  const history = useHistory();
  return (
    <React.Fragment>
      <Button
        size="small"
        onClick={() => {
          history.push(routes.GOALS);
        }}
        data-testid="goal-detail-btn-back"
        className={bem.e("btn-back")}
        icon={<ChevronStartIcon />}
        iconOnly
        text
      />
      <div className={bem.e("right-actions")}>
        <Button
          size="small"
          onClick={() => {
            if (goal.id) {
              window.open(getGoalHubPage(instanceName, goal.id));
            }
          }}
          data-testid="goal-detail-btn-open"
          className={bem.e("btn-link")}
          icon={<Tooltip trigger={<BoxArrowUpRight width={16} height={16} />} message={"Open in Cascade"} />}
          iconOnly
          text
        />
        <CopyToClipboard
          text={getGoalHubPage(instanceName, goal.id)}
          onCopy={() => {
            setIsCopied(true);
            delay(() => setIsCopied(false), 500);
          }}
        >
          <Button
            size="small"
            data-testid="goal-detail-btn-copy"
            className={bem.e("btn-link")}
            icon={<Tooltip trigger={<CopyIcon width={16} height={16} />} message={"Copy link"} />}
            iconOnly
            content={
              isCopied ? (
                <span className={bem.e("copied-text")} data-testid="copy-text">
                  Copied
                </span>
              ) : null
            }
            text
          />
        </CopyToClipboard>
      </div>
    </React.Fragment>
  );
}
function HeaderCreate() {
  return (
    <div className={bem.e("title-create")} data-testid="modal-goal-title">
      <PageTitle title="Create Goal" />
    </div>
  );
}
const GoalModal: React.FC<Props> = (props: Props) => {
  const [bottomHit, setBottomHit] = React.useState(false);
  const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const bottom = target.scrollHeight - target.scrollTop <= target.clientHeight;
    setBottomHit(bottom);
  };
  const {
    modalProps: { isShowConfirmation, ...restModalProps },
    formProps,
  } = props;
  return (
    <BaseModal
      onScroll={e => scrollHandler(e)}
      {...omit(restModalProps, ["setIsShowConfirmation"])}
      closeOnOutsideClick={false}
      className={bem.b([restModalProps.className])}
      header={
        <React.Fragment>
          {formProps.action === ActionMode.Edit || formProps.action === ActionMode.View ? (
            <HeaderEdit goal={formProps.goal} instanceName={formProps.instanceName} />
          ) : (
            <HeaderCreate />
          )}
        </React.Fragment>
      }
      content={
        <div className={bem.e("modal-content")} >
          {isShowConfirmation ? (
            <GoalConfirmation goal={formProps.goal} instanceName={formProps.instanceName} />
          ) : (
            <>
              <GoalForm {...formProps} />
              {formProps.action === ActionMode.Create ? null : (
                <Feed bottomHit={bottomHit} instanceName={formProps.instanceName} goal={formProps.goal} />
              )}
            </>
          )}
        </div>
      }
    ></BaseModal>
  );
};
export default GoalModal;
