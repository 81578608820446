import * as React from "react";
import { getAccessToken } from "api/auth";
import { useQuery } from "utils";
const AuthorizeWindow: React.FC = () => {
  const query: URLSearchParams = useQuery();
  React.useEffect(() => {
    const query = useQuery();
    const code = query.get("code");
    const instance = query.get("state");
    const error = query.get("error");
    if (Office.context.ui.messageParent) {
      if (error) {
        Office.context.ui.messageParent(JSON.stringify({ status: "failure", result: error }));
        return window.close();
      } else {
        if (code && instance) {
          getAccessToken({
            code: code,
            instance: instance,
          })
            .then(({ data }) => {
              Office.context.ui.messageParent(JSON.stringify({ status: "success", result: { ...data, instance } }));
              return window.close();
            })
            .catch((error) => {
              Office.context.ui.messageParent(JSON.stringify({ status: "failure", result: error }));
              return window.close();
            });
        }
      }
    }
  }, [query]);
  return <h3>DONE...</h3>;
};
export default AuthorizeWindow;
