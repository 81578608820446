/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { ValidationErrors } from "type/error";
import { fetchInstanceRegion } from "api/instance";
import { FetchInstanceRegionResponse, FetchInstanceRegionProps } from "api/type";
import { AuthState } from "reducers/auth-slice/type";

export const fetchInstanceRegionThunk = createAsyncThunk<
  FetchInstanceRegionResponse,
  FetchInstanceRegionProps,
  {
    rejectValue: ValidationErrors;
  }
>("auth/fetchInstanceRegion", async (option) => {
  try {
    const response = await fetchInstanceRegion(option.instanceName);
    return {
      data: {
        instanceRegion: response.instanceRegion,
        instanceName: option.instanceName,
        leadId: response.leadId,
      },
    };
  } catch (error) {
    throw Error(error.message);
  }
});

export const fetchInstanceRegionReducers = (builder: ActionReducerMapBuilder<AuthState>): void => {
  builder.addCase(fetchInstanceRegionThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.instanceName = payload.data.instanceName;
    state.instanceRegion = payload.data.instanceRegion;
    state.leadId = payload.data.leadId;
  });
  builder.addCase(fetchInstanceRegionThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(fetchInstanceRegionThunk.rejected, (state, action) => {
    state.isLoading = false;
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};
