/* eslint-disable max-lines-per-function */
import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Flex } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
// components
import GoalRows from "components/goals/goal-rows/GoalRows";
import Pagination from "components/pagination/Pagination";
import PageTitle from "components/page-title/PageTitle";
import EmptyResults from "components/empty-results/EmptyResults";
import Loader from "components/core/loader/Loader";
import { SettingContextualMenu } from "components/core/settings-contextual-menu/SettingContextualMenu";
// reducers
import { Goal } from "reducers/goal-slice/type";
import { goalAction } from "reducers/goal-slice";
import { fetchGoalThunk } from "reducers/goal-slice/reducers/fetchGoalReducer";
import { segmentAnalyticsThunk } from "reducers/auth-slice/reducers/segmentAnalyticsReducer";
import { fetchGoalsThunk } from "reducers/goal-slice/reducers/fetchGoalsReducer";
import { selectGoals, selectIsLoading, selectGoalIds } from "reducers/goal-slice/selector";
import { AppDispatch, useAppDispatch, useAppSelector } from "reducers/store";
import { RootState } from "reducers/rootReducer";
import useScrollToTop from "hook/useScrollToTop";
import { selectUser } from "reducers/auth-slice/selector";
import { authAction } from "reducers/auth-slice";
import { User } from "reducers/user-slice/type";

// other
import { detectURLs, detectGoalIdFromUrl } from "utils";
import bn from "helper/bemnames";
import { HELP_URL } from "constant/url";
import { routes } from "taskpane/navigation/Routes";
import { SEGMENT_INTEGRATION_ID } from "config/enviroment";

const bem = bn.create("goals");
interface Props {}
const GoalsScreen: React.FC<Props> = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const [page, setPage] = React.useState(1);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const goalsIds = useAppSelector<number[]>(selectGoalIds);
  const goals = useAppSelector<Goal[]>(selectGoals);
  const loading = useAppSelector<boolean>(selectIsLoading);
  const pagination = useAppSelector((state: RootState) => state.goal.pagination);
  const user = useAppSelector<User | undefined>(selectUser);
  const instanceName = useAppSelector((state: RootState) => state.auth.instanceName);

  const getGoalIdsFromEmailContent = () => {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, (asyncResult: Office.AsyncResult<string>) => {
      try {
        const parser = new DOMParser();
        const document = parser.parseFromString(asyncResult.value, "text/html");
        const urls = detectURLs(document, instanceName);
        const detectedGoalsIds: number[] = urls.map((url: string) => detectGoalIdFromUrl(url));
        const goalsIds = detectedGoalsIds.filter(e => e !== -1);
        dispatch(goalAction.updateGoalIds(goalsIds));
        setLoaded(true);
      } catch (_error) {
        dispatch(goalAction.updateGoalIds([]));
        setLoaded(true);
      }
    });
  };

  React.useEffect(() => {
    getGoalIdsFromEmailContent();
  }, []);
  React.useEffect(() => {
    if (!user) return;
    dispatch(
      segmentAnalyticsThunk({
        event: "Goal List Viewed",
      })
    );
  }, [get(user, "id")]);

  React.useEffect(() => {
    if (goalsIds.length === 0) return;
    dispatch(
      fetchGoalsThunk({
        page: page,
        sort: "title",
      })
    )
      .then(unwrapResult)
      .then(() => {
        useScrollToTop();
      });
  }, [dispatch, page, goalsIds]);

  const fetchGoal = (id: string | number) =>
    dispatch(fetchGoalThunk({ id }))
      .then(unwrapResult)
      .then((response) => {
        dispatch(
          segmentAnalyticsThunk({
            event: "Goal Viewed",
            options: {
              goal_id: id,
              page_name: "viewGoal",
              source_invoker: SEGMENT_INTEGRATION_ID,
            },
          })
        );
        history.push(`/goal/${response.action}/${id}`);
      });
  const isEmpty = (goalsIds.length === 0 || (pagination && get(pagination, "total", 0) === 0)) && loaded;
  const userName = user ? `${user.attributes.first_name} ${user.attributes.last_name}` : "";
  return (
    <div className={bem.b()}>
      <div className={bem.e("page-header")}>
        <PageTitle title="Related Goals" />
        <SettingContextualMenu
          userName={userName}
          onGetHelpClick={() => {
            dispatch(
              segmentAnalyticsThunk({
                event: "Help Selected",
                options: {
                  target_page_id: HELP_URL,
                  target_page_url: HELP_URL,
                  page_name: "settings",
                },
                isSystem: true,
              })
            );
            window.open(HELP_URL, "_blank");
          }}
          onLogOutClick={() => {
            dispatch(
              segmentAnalyticsThunk({
                event: "User Logout",
                options: {},
                isSystem: true,
              })
            );
            dispatch(authAction.signOutUser(undefined));
            dispatch(authAction.outlookUser(routes.GOALS)); // assign to goals list route for user login back
          }}
        />
      </div>
      {isEmpty ? <EmptyResults /> : <GoalRows goals={goals} fetchGoal={fetchGoal} />}
      {pagination && pagination.total_pages > 1 && (
        <div className={bem.e("page-bottom")}>
          <Flex gap="gap.small">
            <Pagination
              from={(pagination.current_page - 1) * pagination.per_page + 1}
              to={(pagination.current_page - 1) * pagination.per_page + pagination.count}
              total={pagination.total}
              page={pagination.current_page}
              lastPage={pagination.total_pages}
              onChange={setPage}
            />
          </Flex>
        </div>
      )}
      <Loader loading={loading} fluid />
    </div>
  );
};
export default GoalsScreen;
