import * as Yup from "yup";

import { split, trim } from "lodash";

import { Item as DropdownItem } from "components/core/dropdown/type";

import { FormGoalProps } from "api/type";

import { Goal, Included } from "reducers/goal-slice/type";
import { Config } from "reducers/config-slice/type";
import { Template } from "reducers/template-slice/type";
import { GoalProgressStatus } from "reducers/goal-slice/type";
import { Weight } from "reducers/weight-slice/type";
import { User } from "reducers/user-slice/type";
import { Plan, PlanIncluded } from "reducers/plan-slice/type";
import { counter, extractContent } from "helper/common";
export enum ActionMode {
  "Edit" = "edit",
  "Create" = "create",
  "View" = "view",
}

export interface GoalFormProps {
  id?: string | number;
  title: string;
  start_time?: Date;
  end_time?: Date;
  progress: string | number;
  progress_status: string | number;
  progress_percentage: string | number;
  aligned_to?: DropdownItem<PlanIncluded | Goal>;
  owner?: DropdownItem<Included>;
  weight?: DropdownItem<Included>;
  entityTemplate?: DropdownItem<Included>;
  settings?: GoalSettings | string;
  description?: string;
  plan?: DropdownItem<Plan>;
  statusText: GoalProgressStatus | string;
  custom_attributes?: GoalCustomAttributes | string;
  is_add_email?: boolean;
}
export type GoalCustomAttributes = {
  RESERVED_SYSTEM_STATUS?: Array<string>;
};
export interface GoalProps {
  user?: User;
  goal?: Goal;
  desc?: string;
  config: Config[];
  loading?: boolean;
  fetchGoalsByPlan: (
    { id, keyword }: { id: string | number; keyword?: string },
    callback?: (response: Goal[]) => void
  ) => void;
  onSubmit: (values: FormGoalProps) => void;
  action: ActionMode;
  instanceName: string;
  templatesOptions: DropdownItem<Template>[];
  weightsOptions: DropdownItem<Weight>[];
  usersOptions: DropdownItem<User>[];
  plansOptions: DropdownItem<Plan>[];
  goalsByPlanOptions?: DropdownItem<Goal>[];
  initialValues: GoalFormProps;
}
export type GoalSettings = {
  is_private: boolean;
  tracking: {
    autoTrackTargets: boolean;
    completion_criteria: string;
    target_flow: string;
    trackingCurve: string;
    tracking_type: string;
    manualStatus: boolean;
  };
};

export const validationSchema = Yup.object().shape({
  id: Yup.string().notRequired(),
  title: Yup.string()
    .test(
      "min-words",
      "Title field requires at least two words",
      (value: string | undefined): boolean => split(trim(value), " ").length >= 2
    )
    .max(280, "Max length 280 characters")
    .required("Title field requires at least two words"),
  entityTemplate: Yup.mixed().required("Goal type field is required"),
  start_time: Yup.date().required("Start date field is required"),
  end_time: Yup.date().required("End date field is required"),
  weight: Yup.mixed().required("Weight field is required"),
  owner: Yup.mixed().required("Owner field is required"),
  description: Yup.string()
    .notRequired()
    .test("max-description", "Max length 65535 characters", (value: string | undefined): boolean => {
      const stripedHtml = extractContent(value);
      const count = counter(stripedHtml);
      return count <= 65535;
    })
    .nullable(),
  plan: Yup.mixed().notRequired(),
  progress: Yup.number().typeError("Progress must be a number").max(100, "Progress max is 100").notRequired(),
  progress_status: Yup.string().notRequired(),
  progress_percentage: Yup.string().notRequired(),
  aligned_to: Yup.mixed().notRequired(),
  is_add_email: Yup.bool().notRequired(),
});
