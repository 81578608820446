import { createSlice } from "@reduxjs/toolkit";

import { signInReducer } from "./reducers/signInUserReducer";
import { signOutReducer } from "./reducers/signOutUserReducer";
import { outlookReducer } from "./reducers/outlookUserReducer";

import { AuthState } from "./type";
import { getRefreshTokenReducers } from "./reducers/getRefreshTokenReducer";
import { fetchInstanceRegionReducers } from "./reducers/fetchInstanceRegionReducer";
import { fetchProfileReducer } from "./reducers/fetchProfileReducer";

const initialState: AuthState = {
  isLoading: false,
  instanceName: "",
  instanceRegion: "",
  user: undefined,
  leadId: undefined,
};

const { reducer, actions } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOutUser: signOutReducer,
    signInUser: signInReducer,
    outlookUser: outlookReducer,
  },
  extraReducers: (builder) => {
    getRefreshTokenReducers(builder);
    fetchProfileReducer(builder);
    fetchInstanceRegionReducers(builder);
  },
});
export { reducer, actions as authAction };
