import * as React from "react";
import { NoResultsIcon } from "components/core/icons/Icons";
import bn from "helper/bemnames";
const bem = bn.create("empty-results");

interface Props {
  message?: string;
}
const EmptyResults: React.FC<Props> = (props: Props) => {
  const { message = "We couldn't find any link to Cascade goals in this email" } = props;
  return (
    <div className={bem.b()}>
      <div className={bem.e("image")}>
        <NoResultsIcon />
      </div>
      <p className={bem.e("description")}>{message}</p>
    </div>
  );
};
export default EmptyResults;
