import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
// import { FetchFileResponse } from '../type';
import { StatusCode } from 'type/error';
import { RootState } from 'reducers/rootReducer';
import { FileState } from '../type';
import { getAwsUrl, postFileApi, uploadImage, patchFileApi } from 'api/file';

export const postFileThunk = createAsyncThunk<
  string,
  {
    file: File;
    goalId: string;
  },
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('file/postFile', async option => {
  try {
    const createdFile = await postFileApi(option);
    const awsUrl = await getAwsUrl(createdFile.data.id as number);
    const version_id = await uploadImage(awsUrl, option.file);
    const patchedFile = await patchFileApi(option, version_id, createdFile.data.id as number);
    return patchedFile.data.id as string;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const postFileReducer = (builder: ActionReducerMapBuilder<FileState>): void => {
  builder.addCase(postFileThunk.fulfilled, state => {
    state.error = undefined;
    state.isLoading = false;
  });
  builder.addCase(postFileThunk.pending, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(postFileThunk.rejected, (state, action) => {
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
    state.isLoading = false;
  });
};
