import { apiClient } from "./client";
import { FetchGoalsProps, FetchGoalsResponse, FetchGoalResponse, FetchGoalProps, FormGoalProps } from "./type";
import { detectPermissionFromResponseHeaders } from "helper/common";
import { ActionMode } from "components/forms/goal/type";
export const fetchGoalsApi = async ({
  page,
  keyword,
  sort,
  size,
  filter,
  include,
}: FetchGoalsProps): Promise<FetchGoalsResponse> => {
  const url = "/goals";

  const { data } = await apiClient.get(url, {
    params: {
      "page[page]": page,
      sort,
      keyword,
      "page[size]": size,
      filter,
      include,
      "fields[goal]":
        "settings,description,end_time,initial,progress,progress_percentage,progress_status,start_time,status,target,title,owners,aligned_focus_areas,aligned_parent_goals_appro,entityTemplate,unit,weight,custom_attributes",
    },
  });
  return data;
};

export const fetchGoalApi = async ({ id }: FetchGoalProps): Promise<FetchGoalResponse> => {
  const url = `/goals/${id}?fields[goal]=settings,description,end_time,initial,progress,progress_percentage,progress_status,start_time,status,target,title,owners,aligned_focus_areas,aligned_parent_goals,entityTemplate,unit,weight,custom_attributes,updates&fields[update]=id&include=updates,aligned_parent_goals.id`;
  const { data, headers } = await apiClient.get<FetchGoalResponse>(url);
  const isEdit = detectPermissionFromResponseHeaders(headers);
  return {
    ...data,
    action: isEdit ? ActionMode.Edit : ActionMode.View,
  };
};
export const createGoalApi = async (params: FormGoalProps): Promise<FetchGoalResponse> => {
  const url = `/goals`;

  const { data } = await apiClient.post<FetchGoalResponse>(url, params);
  return data;
};

export const updateGoalApi = async (
  params: FormGoalProps & {
    id: string | number;
  }
): Promise<FetchGoalResponse> => {
  const url = `/goals/${params.id}?fields[goal]=settings,description,end_time,initial,progress,progress_percentage,progress_status,start_time,status,target,title,owners,aligned_focus_areas,aligned_parent_goals_appro,entityTemplate,unit,weight,custom_attributes`;

  const { data } = await apiClient.patch<FetchGoalResponse>(url, params);
  return data;
};
export default { fetchGoalsApi, fetchGoalApi, createGoalApi, updateGoalApi };
