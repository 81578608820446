import React, { useRef } from "react";

import { DropdownProps } from "@fluentui/react-northstar";
import { AcceptIcon, CloseIcon } from "components/core/icons/Icons";
import FormMessage from "components/core/form-message/FormMessage";
import bn from "helper/bemnames";
import { useOnClickOutside } from "hook/useOnClickOutside";

import classNames from "classnames";
import StatusDropDown from "../statusDropdown/StatusDropDown";
import { GoalProgressStatus } from "reducers/goal-slice/type";
const bem = bn.create("form-field");
type Props = DropdownProps & {
  handleDropDownChange: (e: GoalProgressStatus | string) => void;
  isShowActions?: boolean;
  dirty?: boolean;
  manual: boolean;
  errorMessage?: string | string[];
  actionsProps?: {
    handleSave?: () => void;
    handleBack?: () => void;
  };
};
const FormStatusDropDown: React.FC<Props> = (props: Props) => {
  const { manual, error, errorMessage, actionsProps, dirty, isShowActions, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (!dirty) return;
    actionsProps?.handleSave && actionsProps?.handleSave();
  });
  return (
    <div className={bem.b()} ref={ref}>
      <StatusDropDown manual={manual} {...rest} pointer={true} />
      <div className={bem.e("bottom")}>
        {error && <FormMessage data-testid="error" error content={errorMessage} />}
        {isShowActions && (
          <div className={bem.e("actions")} data-testid="actions">
            <span className={classNames(bem.e("accept"), bem.e("button"))} onClick={actionsProps?.handleSave}>
              <AcceptIcon />
            </span>
            <span className={classNames(bem.e("close"), bem.e("button"))} onClick={actionsProps?.handleBack}>
              <CloseIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default FormStatusDropDown;
