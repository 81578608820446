import { AUTH_CALLBACK_URI, CASCADE_URL } from "config/enviroment";

export const REDIRECT_URI = AUTH_CALLBACK_URI;

export const SCOPES = "";

export const AUTH_REQUEST_TOKEN_URL = `${CASCADE_URL}/api/oauth/token.php`;

export const PUBLIC_API = `${CASCADE_URL}/api/public`;

export const HELP_URL = "http://help.cascade.app/en/articles/5222380-cascade-for-outlook";
