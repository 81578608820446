import * as React from "react";
import bn from "helper/bemnames";
import {
  ChevronStartIcon,
  ChevronEndIcon,
  ChevronForceStartIcon,
  ChevronForceEndIcon,
} from "components/core/icons/Icons";
import classNames from "classnames";
const bem = bn.create("pagination");
interface Props {
  lastPage: number;
  page: number;
  from: number;
  to: number;
  total: number;
  onChange: (page: number) => void;
}
const Pagination: React.FunctionComponent<Props> = ({ from, to, total, page, lastPage, onChange }: Props) => {
  const isDisabledPrev = page === 1;
  const isDisabledNext = page === lastPage;
  return (
    <div className={bem.b()}>
      <div className={bem.e("page-text")} data-testid="page-text">
        {from}-{to} of {total}
      </div>
      <div className={bem.e("page-buttons")}>
        <button
          className={classNames(bem.e("page-button"), {
            [bem.e("page-button-disabled")]: isDisabledPrev,
          })}
          data-testid="first-page"
          disabled={isDisabledPrev}
          onClick={() => onChange(1)}
        >
          <ChevronForceStartIcon />
        </button>
        <button
          className={classNames(bem.e("page-button"), {
            [bem.e("page-button-disabled")]: isDisabledPrev,
          })}
          data-testid="prev-page"
          disabled={isDisabledPrev}
          onClick={() => onChange(page - 1)}
        >
          <ChevronStartIcon />
        </button>
        <button
          className={classNames(bem.e("page-button"), {
            [bem.e("page-button-disabled")]: isDisabledNext,
          })}
          data-testid="next-page"
          disabled={isDisabledNext}
          onClick={() => onChange(page + 1)}
        >
          <ChevronEndIcon />
        </button>
        <button
          className={classNames(bem.e("page-button"), {
            [bem.e("page-button-disabled")]: isDisabledNext,
          })}
          data-testid="last-page"
          disabled={isDisabledNext}
          onClick={() => onChange(lastPage)}
        >
          <ChevronForceEndIcon />
        </button>
      </div>
    </div>
  );
};
export default Pagination;
