/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ValidationErrors } from "type/error";
import { RootState } from "reducers/rootReducer";
import { selectAuth } from "../selector";
import { SEGMENT_WRITE_KEY, SEGMENT_INTEGRATION_ID } from "config/enviroment";
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
const analytics = Analytics({
  app: "cascade-outlook-app",
  plugins: [
    segmentPlugin({
      writeKey: SEGMENT_WRITE_KEY,
    }),
  ],
});

type SegmentAnalyticsProps = {
  event: string;
  options?: Record<string, unknown>;
  isSystem?: boolean;
  action?: string;
};
export const segmentAnalyticsThunk = createAsyncThunk<
  void,
  SegmentAnalyticsProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>("auth/segmentAnalytics", async ({ event, options = {}, isSystem = false, action = null }, { getState }) => {
  try {
    const authData = selectAuth(getState());
    let params: {
      user_id: string | number;
      lead_id: string;
      system?: string;
    } = {
      ...options,
      user_id: `${authData.leadId}-${authData.user.id}`,
      lead_id: authData.leadId,
    };
    if (isSystem) {
      params = {
        ...params,
        system: SEGMENT_INTEGRATION_ID,
      };
    }
    if (action === "login") {
      analytics.identify(`${authData.leadId}-${authData.user.id}`, {
        lead_id: authData.leadId,
      });
      // @ts-ignore
      analytics.plugins.segment.group(authData.leadId, {});
    }
    analytics.track(event, params);
    return;
  } catch (error) {
    throw Error(error.message);
  }
});
