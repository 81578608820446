import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import bn from "helper/bemnames";
import { Flex } from "@fluentui/react-northstar";
import { Logo } from "components/core/icons/Icons";
import Button from "components/core/button/Button";
import Alert from "components/alert/Alert";
import Loader from "components/core/loader/Loader";
import { useHistory } from "react-router-dom";
import { TokenState } from "reducers/auth-slice/type";
import { formatTokenState } from "helper/common";
import { authAction } from "reducers/auth-slice";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { routes } from "../navigation/Routes";
import { selectOutlook, selectIsLoading } from "reducers/auth-slice/selector";
import { fetchProfileThunk } from "reducers/auth-slice/reducers/fetchProfileReducer";
import { segmentAnalyticsThunk } from "reducers/auth-slice/reducers/segmentAnalyticsReducer";
import { fetchInstanceRegionThunk } from "reducers/auth-slice/reducers/fetchInstanceRegionReducer";
import { apiClient } from "api/client";
import { prepareAPIBaseUrl } from "helper/string";

const bem = bn.create("welcome-screen");

const WelcomeScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  let dialog: Office.Dialog;
  const isAuthLoading = useAppSelector<boolean>(selectIsLoading);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertVisible, setAlertVisible] = React.useState<boolean>(false);
  const outlookRoute = useAppSelector(selectOutlook);
  const failedToLogin = () => {
    setLoading(false);
    dispatch(authAction.signOutUser(undefined));
  };
  const openLogin = () => {
    const url = window.location.origin + routes.AUTH;
    setLoading(true);
    Office.context.ui.displayDialogAsync(url, { height: 70, width: 70 }, (asyncResult) => {
      dialog = asyncResult.value;
      setLoading(false);
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
    });
  };
  const processMessage = (arg: any) => {
    const message = JSON.parse(arg.message);
    if (message.status === "success") {
      setAlertVisible(true);
      const tokenState: TokenState = formatTokenState(message.result);
      const instanceName = tokenState?.instance || "";
      dispatch(
        fetchInstanceRegionThunk({
          instanceName,
        })
      )
        .then(unwrapResult)
        .then((responseInstanceRegion) => {
          apiClient.defaults.baseURL = prepareAPIBaseUrl(
            responseInstanceRegion.data.instanceName,
            responseInstanceRegion.data.instanceRegion
          );
          dispatch(authAction.signInUser(tokenState));
          dispatch(fetchProfileThunk())
            .then(unwrapResult)
            .then(() => {
              history.push(outlookRoute);
              dispatch(
                segmentAnalyticsThunk({
                  event: "User Login",
                  options: {},
                  isSystem: true,
                  action: "login",
                })
              );
            });
        })
        .catch(failedToLogin);
    } else {
      failedToLogin();
    }
    dialog.close();
  };
  return (
    <div className={bem.b()}>
      <Flex column hAlign="center">
        <div className={bem.e("logo")}>
          <Logo />
        </div>
        <h1 className={bem.e("title")}>Welcome</h1>
        <p className={bem.e("subtitle")}>
          Turn Strategy into execution.
          <br /> Manage and track your goals from Outlook!
        </p>
        <Button
          className={bem.e("btn-sign-in")}
          data-testid="welcome-btn-sign-in"
          disabled={loading}
          onClick={openLogin}
          content={"Log in"}
          primary
          size="medium"
        />
        <a target="_blank" href="https://www.cascade.app/trial" className={bem.e("btn-sign-up")} rel="noreferrer">
          Sign up for free trial
        </a>
        <Alert visible={alertVisible} className={bem.e("alert-success")} success content="Login successfully" />
      </Flex>
      <Loader loading={isAuthLoading} fluid />
    </div>
  );
};
export default WelcomeScreen;
