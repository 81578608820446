import { apiClient } from "./client";
import {
  FetchPlansProps,
  FetchPlansResponse,
  FetchPlanResponse,
  FetchPlanProps,
  FetchGoalsByPlanProps,
  FetchGoalsResponse,
} from "./type";

export const fetchPlansApi = async ({
  page,
  keyword,
  sort,
  size,
  filter,
  include,
}: FetchPlansProps): Promise<FetchPlansResponse> => {
  const url = "/plans";

  const { data } = await apiClient.get(url, {
    params: { "page[page]": page, keyword, sort, "page[size]": size, filter, include },
  });
  return data;
};

export const fetchPlanApi = async ({ id }: FetchPlanProps): Promise<FetchPlanResponse> => {
  const url = `/plans/${id}`;

  const { data } = await apiClient.get<FetchPlanResponse>(url);
  return data;
};

export const fetchGoalsByPlanApi = async ({ id }: FetchGoalsByPlanProps): Promise<FetchGoalsResponse> => {
  const url = `/plans/${id}/goals`;

  const { data } = await apiClient.get(url);
  return data;
};

export default { fetchPlansApi, fetchPlanApi, fetchGoalsByPlanApi };
