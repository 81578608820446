import * as React from "react";
import { useLocation } from "react-router-dom";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { selectIsAuthenticated, selectAuth } from "reducers/auth-slice/selector";
import { routes } from "taskpane/navigation/Routes";
import { apiClient } from "api/client";
import { prepareAPIBaseUrl, prepareAuthorizationToken } from "helper/string";
import { fetchProfileThunk } from "reducers/auth-slice/reducers/fetchProfileReducer";

import { authAction } from "reducers/auth-slice";
import { includes } from "lodash";

const PrivateRoute: React.FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const { tokenState, instanceName, instanceRegion } = useAppSelector(selectAuth);

  if (isAuthenticated && tokenState && instanceRegion) {
    apiClient.defaults.headers.common["Authorization"] = prepareAuthorizationToken(
      tokenState.accessToken,
      tokenState.tokenType
    );
    apiClient.defaults.baseURL = prepareAPIBaseUrl(instanceName, instanceRegion);
  }

  React.useEffect(() => {
    // save first route for redirect after login success.
    if (includes([routes.GOALS, routes.CREATE_GOAL], location.pathname)) {
      dispatch(authAction.outlookUser(location.pathname));
    }
    if (isAuthenticated) {
      dispatch(fetchProfileThunk()); // re-fetch user information
    }
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /sign-in page
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && instanceRegion ? <Component {...props} /> : <Redirect to={routes.SIGN_IN} />
      }
    />
  );
};

export default PrivateRoute;
