import * as React from "react";
import bn from "helper/bemnames";
import { Included } from "reducers/goal-slice/type";
import classNames from "classnames";
const bem = bn.create("owner");
interface Props {
  owner: Included;
  isText?: boolean;
}
const Owner: React.FC<Props> = ({ owner, isText }: Props) => {
  const attributes = owner?.attributes;
  const firstName = attributes && attributes.first_name ? attributes.first_name : "";
  const lastName = attributes && attributes.last_name ? attributes.last_name : "";
  const sortName = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return (
    <div className={classNames(bem.b(), isText && bem.m("owner-text"))}>
      {isText ? (
        <span className={bem.e("text")}>{`${firstName} ${lastName}`}</span>
      ) : (
        <span className={bem.e("text")}>{sortName}</span>
      )}
    </div>
  );
};
export default Owner;
