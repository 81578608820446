import React from 'react';
import Button from "components/core/button/Button";
import { getGoalHubPage } from "helper/common";
import { BoxArrowUpRight } from "components/core/icons/Icons";
import Tooltip from "components/tooltip/Tooltip";
import bn from "helper/bemnames";

const GoalMessage = props => {
    const bem = bn.create("goal-modal");

    const openLink = () => {
        window.open(getGoalHubPage(props.instanceName, props.savedGoalId));
    }

    return (
        <div className="modal-created">
            <p>Goal Created!
                <Button
                size="small"
                onClick={openLink}
                data-testid="goal-detail-btn-open"
                className={bem.e("btn-link")}
                icon={<Tooltip trigger={<BoxArrowUpRight width={16} height={16} />} message={"Open in Cascade"} />}
                iconOnly
                text
            /></p>
            <div className="buttons">
                <Button onClick={() => props.setShowModal(false)} primary content="Add another" size="medium"/>
                <Button onClick={() => Office.context.ui.closeContainer()} content="Done" size="medium"/>
            </div>
        </div>
    )
}

export default GoalMessage;