import { createSlice } from "@reduxjs/toolkit";
import { fetchWeightsReducer } from "./reducers/fetchWeightsReducer";

import { WeightState } from "./type";
const initialState: WeightState = { weights: [], isLoading: false };

const { reducer, actions } = createSlice({
  name: "weight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchWeightsReducer(builder);
  },
});
export { reducer, actions as weightAction };
