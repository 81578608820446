/* eslint-disable no-param-reassign */
import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getRefreshToken } from "api/auth";

import { RootState } from "reducers/rootReducer";
import { ValidationErrors } from "type/error";
import { AuthState, TokenState } from "../type";

export const getRefreshTokenThunk = createAsyncThunk<
  TokenState,
  void,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>("auth/getRefreshToken", async (_, { getState }) => {
  try {
    const state = getState();

    const refreshToken = state.auth.tokenState?.refreshToken ?? "";
    const instance = state.auth.instanceName;

    const {
      data: { refresh_token, expires_in, access_token, token_type },
    } = await getRefreshToken({ refresh_token: refreshToken, instance });

    return { refreshToken: refresh_token, expiresIn: expires_in, accessToken: access_token, tokenType: token_type };
  } catch (error) {
    throw Error(error.message);
  }
});

export const getRefreshTokenReducers = (builder: ActionReducerMapBuilder<AuthState>): void => {
  builder.addCase(getRefreshTokenThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.error = undefined;
    state.tokenState = payload;
  });

  builder.addCase(getRefreshTokenThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });

  builder.addCase(getRefreshTokenThunk.rejected, (state, action) => {
    state.isLoading = false;
    state.tokenState = undefined;
    if (action.payload) {
      // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
  });
};
