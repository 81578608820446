/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-lines */
import { createSelector } from "@reduxjs/toolkit";
import { GoalProgressStatus } from "./type";
import { RootState } from "reducers/rootReducer";
import { Goal, Included, IncludedRelationShip } from "reducers/goal-slice/type";
import { IncludedRelationships, User } from "reducers/user-slice/type";
import { flatten, isEmpty, first } from "lodash";
import { Item as DropdownItem } from "components/core/dropdown/type";
// import * as moment from "moment";
import { Weight } from "reducers/weight-slice/type";
import { Template } from "reducers/template-slice/type";
import { GoalFormProps } from "components/forms/goal/type";
import { getManualStatus } from "helper/goal";
const moment = require("moment");

// helpers
const getOwner = (owners: IncludedRelationships<Included[]>, usersOptions: DropdownItem<User>[]) => {
  const { data } = owners;

  if (isEmpty(data)) {
    return {
      header: "Unassigned",
      data: null,
    };
  }

  const owner = usersOptions.find((item) => item.data?.type === first(data)?.type && item.data?.id === first(data)?.id);

  if (owner)
    return {
      header: owner.header ? owner.header : "",
      data: {
        id: owner?.data?.id ? owner?.data?.id : "",
        type: owner?.data?.type ? owner?.data?.type : "",
        attributes: owner?.data?.attributes,
        relationships: owner?.data?.relationships,
      },
    };
  return undefined;
};
const getIncludeData = (data: Included, included: Included[]): Included[] | Included => {
  const includedItems = included.filter((item) => item.id === data.id && item.type === data.type);
  if (includedItems.length === 0) {
    return [];
  }
  const includedItem = includedItems[0];
  const relationships = includedItem.relationships;
  if (!relationships) {
    return includedItem;
  }

  const relationship = relationships.user;
  if (!relationship || !relationship.data) {
    return [];
  }

  return included.filter((item) => item.id === relationship.data?.id && item.type === relationship.data.type);
};

// selectors
export const selectGoalIds = (state: RootState): number[] => state.goal.goalIds || [];
export const selectGoals = createSelector(
  (state: RootState) => state.goal.goals,
  (state: RootState) => state.goal.included,
  (goals, included) => {
    return goals.map((goal: Goal) => {
      const weightData = goal.relationships.weight.data
        ? included.find(
            (item) =>
              item.type === goal.relationships.weight.data?.type && item.id === goal.relationships.weight.data?.id
          )
        : undefined;
      const entityTemplateData = goal.relationships.entityTemplate.data
        ? included.find(
            (item) =>
              item.type === goal.relationships.entityTemplate.data?.type &&
              item.id === goal.relationships.entityTemplate.data?.id
          )
        : undefined;
      const unitData = goal.relationships.unit.data
        ? included.find(
            (item) => item.type === goal.relationships.unit.data?.type && item.id === goal.relationships.unit.data?.id
          )
        : undefined;

      const ownersData = flatten(
        goal.relationships?.owners?.data?.map((item) => {
          return getIncludeData(item, included);
        })
      );

      const updatesData = flatten(
        goal.relationships?.updates?.data?.map(item => {
          return getIncludeData(item, included);
        }),
      );

      return {
        ...goal,
        relationships: {
          weight: {
            data: weightData,
          },
          entityTemplate: {
            data: entityTemplateData,
          },
          unit: {
            data: unitData,
          },
          owners: {
            data: ownersData,
          },
          updates: {
            data: updatesData,
          },
        },
      };
    });
  }
);

export const selectError = (state: RootState): string | undefined | null => state.goal.error;

export const selectIsLoading = (state: RootState): boolean => state.goal.isLoading;
export const selectIsLoadingGoal = (state: RootState): boolean => state.goal.isLoadingGoal as boolean;
export const selectRoleOwner = (state: RootState): IncludedRelationShip<Included[]> | undefined =>
  state.goal.goal?.relationships?.owners;

export const selectGoal = createSelector(
  (state: RootState): Goal | undefined => state.goal.goal,
  (state: RootState) => state.goal.includedDetail,
  (goal, includedDetail) => {
    if (!goal || !goal.relationships) return undefined;
    const weightData = goal.relationships.weight.data
      ? includedDetail.find(
          (item) => item.type === goal.relationships.weight.data?.type && item.id === goal.relationships.weight.data?.id
        )
      : undefined;
    const entityTemplateData = goal.relationships.entityTemplate.data
      ? includedDetail.find(
          (item) =>
            item.type === goal.relationships.entityTemplate.data?.type &&
            item.id === goal.relationships.entityTemplate.data?.id
        )
      : undefined;
    const unitData = goal.relationships.unit.data
      ? includedDetail.find(
          (item) => item.type === goal.relationships.unit.data?.type && item.id === goal.relationships.unit.data?.id
        )
      : undefined;

    const ownersData = flatten(
      goal.relationships?.owners?.data?.map((item) => {
        return getIncludeData(item, includedDetail);
      })
    );

    const updatesData = flatten(
      goal.relationships?.updates?.data?.map(item => {
        return getIncludeData(item, includedDetail);
      }),
    );

    return {
      ...goal,
      relationships: {
        weight: {
          data: weightData,
        },
        entityTemplate: {
          data: entityTemplateData,
        },
        unit: {
          data: unitData,
        },
        owners: {
          data: ownersData,
        },
        updates: {
          data: updatesData,
        },
      },
    };
  }
);

export const selectInitialValuesForm = ({
  goal,
  isCreate,
  user,
  desc,
  defaultWeight,
  defaultTemplate,
  usersOptions,
}: {
  goal?: Goal;
  isCreate: boolean;
  user?: User;
  desc?: string;
  defaultWeight: DropdownItem<Weight>;
  defaultTemplate: DropdownItem<Template>;
  usersOptions: DropdownItem<User>[];
}): GoalFormProps => {
  const dateEndOfYear = moment().endOf("year").toDate();
  const today = new Date();
  const customAttributes = goal?.attributes.custom_attributes as [];
  const emptyAttributes = customAttributes?.length == 0;
  const initialValues = {
    id: !isCreate && goal ? goal?.id : "",
    title: !isCreate && goal ? goal?.attributes.title : "",
    start_time: !isCreate && goal ? moment(goal?.attributes.start_time).startOf("day").toDate() : today,
    end_time: !isCreate && goal ? moment(goal?.attributes.end_time).startOf("day").toDate() : dateEndOfYear,
    progress: !isCreate && goal ? goal?.attributes.progress : "",
    progress_status: !isCreate && goal ? goal?.attributes.progress_status : "",
    progress_percentage: !isCreate && goal ? goal?.attributes.progress_percentage : "",
    settings: !isCreate && goal ? goal?.attributes.settings : "",
    custom_attributes: !isCreate && goal && !emptyAttributes ? goal?.attributes.custom_attributes : "",
    description: desc ? desc : !isCreate && goal && goal?.attributes.description ? goal?.attributes.description : "",
    statusText:
      !isCreate && goal && goal.attributes?.settings?.tracking?.manualStatus
        ? (getManualStatus(goal.attributes) as GoalProgressStatus)
        : !isCreate
        ? (goal?.attributes.progress_status as GoalProgressStatus)
        : "",
    owner:
      !isCreate &&
      goal &&
      goal?.relationships &&
      goal?.relationships.owners.data &&
      goal?.relationships.owners.data?.length > 0
        ? getOwner(goal?.relationships.owners as IncludedRelationships<Included[]>, usersOptions)
        : user
        ? {
            header: `${user?.attributes.first_name} ${user?.attributes.last_name}`,
            data: {
              id: user?.id,
              type: user.type,
              attributes: user?.attributes,
              relationships: user.relationships,
            },
          }
        : undefined,
    weight:
      !isCreate && goal && goal?.relationships && goal?.relationships.weight.data
        ? {
            header: goal?.relationships.weight.data.attributes?.name
              ? goal?.relationships.weight.data.attributes?.name
              : "",
            data: goal?.relationships.weight.data,
          }
        : defaultWeight
        ? {
            header: defaultWeight.header,
            data: defaultWeight.data,
          }
        : undefined,
    entityTemplate:
      !isCreate && goal && goal?.relationships && goal?.relationships.entityTemplate.data
        ? {
            header: goal?.relationships.entityTemplate.data.attributes?.name
              ? goal?.relationships.entityTemplate.data.attributes?.name
              : "",
            data: goal?.relationships.entityTemplate.data,
          }
        : defaultTemplate
        ? {
            header: defaultTemplate.header,
            data: defaultTemplate.data,
          }
        : undefined,
    plan: undefined,
    aligned_to: undefined,
  };
  return initialValues;
};
