/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from "react";
import Datepicker from "components/core/datepicker/Datepicker";
import { DatepickerProps } from "@fluentui/react-northstar";
import { AcceptIcon, CloseIcon } from "components/core/icons/Icons";
import FormMessage from "components/core/form-message/FormMessage";
import bn from "helper/bemnames";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
// import * as moment from "moment";
import { useOnClickOutside } from "hook/useOnClickOutside";
const moment = require("moment");
const bem = bn.create("form-field");
type Props = DatepickerProps & {
  isShowActions?: boolean;
  errorMessage?: string;
  error?: boolean;
  dirty?: boolean;
  actionsProps?: {
    handleSave?: () => void;
    handleBack?: () => void;
  };
};
const FormDatepicker: React.FC<Props> = (props: Props) => {
  const { error, errorMessage, actionsProps, isShowActions, dirty, ...rest } = props;
  const key = uuidv4(); // need to generate new key for apply new props to datepicker
  const ref = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<any>(null);
  useOnClickOutside(ref, () => {
    if (!dirty || contentRef.current) return;
    actionsProps?.handleSave && actionsProps?.handleSave();
  });
  return (
    <div className={bem.b()} ref={ref}>
      <Datepicker
        {...rest}
        popup={{
          contentRef: contentRef,
        }}
        className={bem.e("datepicker")}
        formatMonthDayYear={(date: Date) => moment(date).format("MMM DD, YYYY")}
        key={key}
      />
      <div className={bem.e("bottom")}>
        {error && <FormMessage data-testid="error" error content={errorMessage} />}
        {isShowActions && (
          <div className={bem.e("actions")} data-testid="actions">
            <span className={classNames(bem.e("accept"), bem.e("button"))} onClick={actionsProps?.handleSave}>
              <AcceptIcon />
            </span>
            <span className={classNames(bem.e("close"), bem.e("button"))} onClick={actionsProps?.handleBack}>
              <CloseIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default FormDatepicker;
