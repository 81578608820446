/* eslint-disable max-lines-per-function */
import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// components
import Loader from "components/core/loader/Loader";
import GoalModal, { GoalModalProps } from "components/modals/goal/Goal";
import { Item as DropdownItem } from "components/core/dropdown/type";
import { ActionMode, GoalFormProps } from "components/forms/goal/type";
import { selectInitialValuesForm } from "reducers/goal-slice/selector";
import Button from "components/core/button/Button";
import GoalMessage from "components/goal-message/GoalMessage";

// reducers
import { FormGoalProps } from "api/type";
import { Goal } from "reducers/goal-slice/type";
import { fetchGoalThunk } from "reducers/goal-slice/reducers/fetchGoalReducer";
import { updateGoalThunk } from "reducers/goal-slice/reducers/updateGoalReducer";
import { createGoalThunk } from "reducers/goal-slice/reducers/createGoalReducer";
import { fetchGoalsThunk } from "reducers/goal-slice/reducers/fetchGoalsReducer";
import { selectIsLoading, selectGoal } from "reducers/goal-slice/selector";
import { AppDispatch, useAppDispatch, useAppSelector } from "reducers/store";
import { segmentAnalyticsThunk } from "reducers/auth-slice/reducers/segmentAnalyticsReducer";

import { selectUser } from "reducers/auth-slice/selector";
import { Template } from "reducers/template-slice/type";
import { fetchTemplatesThunk } from "reducers/template-slice/reducers/fetchTemplatesReducer";
import { selectTemplatesForDropdown } from "reducers/template-slice/selector";

import { fetchWeightsThunk } from "reducers/weight-slice/reducers/fetchWeightsReducer";
import { selectWeightsForDropdown } from "reducers/weight-slice/selector";
import { Weight } from "reducers/weight-slice/type";

import { fetchUsersThunk } from "reducers/user-slice/reducers/fetchUsersReducer";
import { selectUsersForDropdown } from "reducers/user-slice/selector";
import { User } from "reducers/user-slice/type";
import { Config } from "reducers/config-slice/type";
import { selectConfig } from "reducers/config-slice/selector";
import { fetchConfigThunk } from "reducers/config-slice/reducers/fetchConfigReducer";
import { fetchPlansThunk } from "reducers/plan-slice/reducers/fetchPlansReducer";
import { fetchGoalsByPlanThunk } from "reducers/plan-slice/reducers/fetchGoalsByPlanReducer";
import { selectPlansForDropdown, selectGoalsByPlanForDropdown } from "reducers/plan-slice/selector";
import { Plan } from "reducers/plan-slice/type";
import { SEGMENT_INTEGRATION_ID } from "config/enviroment";

// other
import bn from "helper/bemnames";

const bem = bn.create("goal-create");
interface Props {
  onCloseModal?: () => void;
  modalProps: GoalModalProps;
  desc?: string;
}

const GoalFormScreen: React.FC<Props> = ({ modalProps, desc }: Props) => {
  const params: {
    action?: string;
    id?: string | number;
  } = useParams();
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const action = params.action ?? ActionMode.Create;
  const [isShowConfirmation, setIsShowConfirmation] = React.useState<boolean>(false);
  const instanceName = useAppSelector<string>((state) => state.auth.instanceName);
  const user = useAppSelector<User | undefined>(selectUser);
  const goal = useAppSelector<Goal | undefined>(selectGoal);

  const templatesOptions = useAppSelector<DropdownItem<Template>[]>(selectTemplatesForDropdown);
  const config = useAppSelector<Config[]>(selectConfig);
  const weightsOptions = useAppSelector<DropdownItem<Weight>[]>(selectWeightsForDropdown);
  const usersOptions = useAppSelector<DropdownItem<User>[]>(selectUsersForDropdown);
  const plansOptions = useAppSelector<DropdownItem<Plan>[]>(selectPlansForDropdown);
  const goalsByPlanOptions = useAppSelector<DropdownItem<Goal>[]>(selectGoalsByPlanForDropdown);
  const loading = useAppSelector<boolean>(selectIsLoading);

  const [showModal, setShowModal] = React.useState(false);
  const [savedGoalId, setSavedGoalId] = React.useState(null);

  React.useEffect(() => {
    dispatch(fetchTemplatesThunk({}));
    dispatch(fetchWeightsThunk({}));
    dispatch(fetchUsersThunk({}));
    dispatch(fetchConfigThunk({}));
    dispatch(
      fetchPlansThunk({
        include: "focusAreas",
      })
    );
  }, [dispatch]);
  const fetchGoalsByPlan = ({ id }: { id: string | number; keyword?: string }, callback?: (data: Goal[]) => void) => {
    dispatch(fetchGoalsByPlanThunk({ id, sort: "title" }))
      .then(unwrapResult)
      .then((response) => {
        if (callback) callback(response.data);
      });
  };

  const onSubmit = (values: FormGoalProps) => {
    if (action === ActionMode.Create) {
      dispatch(createGoalThunk(values))
        .then(unwrapResult)
        .then((response) => {
          dispatch(
            segmentAnalyticsThunk({
              event: "Goal Created",
              options: {
                goal_id: response.data.id,
                page_name: "createGoal",
                source_invoker: SEGMENT_INTEGRATION_ID,
                source_input: "integration",
                tags: values.data.relationships.aligned_parent_goals_appro?.data,
                owner_id:
                  values.data.relationships.owners.data && values.data.relationships.owners.data[0]
                    ? values.data.relationships.owners.data[0].id
                    : "",
                goal_state: "active",
                goal_template_id: values.data.relationships.entity_template.data.id,
                creator_id:
                  values.data.relationships.owners.data && values.data.relationships.owners.data[0]
                    ? values.data.relationships.owners.data[0].id
                    : "",
              },
            })
          );
          setSavedGoalId(response.data.id);
          setShowModal(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (goal) {
      dispatch(
        updateGoalThunk({
          ...values,
          id: goal.id,
        })
      )
        .then(unwrapResult)
        .finally(() => {
          dispatch(
            segmentAnalyticsThunk({
              event: "Goal Updated",
              options: {
                goal_id: goal.id,
                page_name: "viewGoal",
                source_invoker: SEGMENT_INTEGRATION_ID,
                source_input: "integration",
                tags: values.data.relationships.aligned_parent_goals_appro?.data,
                owner_id:
                  values.data.relationships.owners.data && values.data.relationships.owners.data[0]
                    ? values.data.relationships.owners.data[0].id
                    : "",
                goal_state: "active",
                goal_template_id: values.data.relationships.entity_template.data.id,
              },
            })
          );
          dispatch(fetchGoalsThunk({ page: 1, sort: "title" }));
        });
    }
  };

  const getInitialValues = (): GoalFormProps => {
    const defaultTemplate = templatesOptions[0];
    const defaultWeight = weightsOptions[0];
    const initialValues = selectInitialValuesForm({
      goal,
      isCreate: action === ActionMode.Create,
      user,
      defaultTemplate,
      defaultWeight,
      usersOptions,
    });
    return initialValues;
  };
  const initialValues = getInitialValues();
  // waiting for render initial values
  if ((!initialValues.entityTemplate || !initialValues.weight) && action === ActionMode.Create)
    return <Loader loading={true} fluid />;
  return (
    <div className={bem.b()}>
      {showModal && action === ActionMode.Create ? <GoalMessage setShowModal={setShowModal} savedGoalId={savedGoalId} instanceName={instanceName} /> : null}
      <GoalModal
        modalProps={{
          ...modalProps,
          isShowConfirmation,
          setIsShowConfirmation,
        }}
        formProps={{
          action: action as ActionMode,
          goal,
          desc,
          user,
          config,

          instanceName,
          initialValues,
          templatesOptions,
          weightsOptions,
          usersOptions,
          plansOptions,
          goalsByPlanOptions,
          fetchGoalsByPlan,
          onSubmit,
        }}
      />
      <Loader loading={loading} fluid />
    </div>
  );
};
GoalFormScreen.defaultProps = {
  modalProps: {
    open: true,
    backdrop: false,
    className: bem.e("goal-modal"),
    isShowConfirmation: false,
  },
};
export default GoalFormScreen;
