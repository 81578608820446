import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ValidationErrors } from 'type/error';
import { FetchFileProps, FetchFileResponse } from '../type';
import { StatusCode } from 'type/error';
import { RootState } from 'reducers/rootReducer';
import { FileState } from '../type';
import { fetchFileApi } from 'api/file';

export const fetchFileThunk = createAsyncThunk<
  FetchFileResponse,
  FetchFileProps,
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>('file/fetchFile', async option => {
  try {
    const response = await fetchFileApi({ id: option.id });
    return response;
  } catch (error) {
    throw Error(error.message ? error.message : error.statusCode ? error.statusCode : StatusCode.Offline);
  }
});

export const fetchFileReducer = (builder: ActionReducerMapBuilder<FileState>): void => {
  builder.addCase(fetchFileThunk.fulfilled, (state, { payload }) => {
    state.error = undefined;
    state.file = payload.data;
    state.isLoading = false;
  });
  builder.addCase(fetchFileThunk.pending, state => {
    state.error = undefined;
    state.isLoading = true;
  });
  builder.addCase(fetchFileThunk.rejected, (state, action) => {
    if (action.payload) {
      state.error = action.payload.errorMessage;
    } else {
      state.error = action.error.message;
    }
    state.isLoading = false;
  });
};
