import { toLower } from "lodash";

export function useQuery(): URLSearchParams {
  const location = window.location.search;
  return new URLSearchParams(location);
}

export function detectURLs(document: Document, instanceName: string): string[] {
  const urls = [];
  const aTags = document.getElementsByTagName('a');

  for(let aTag of aTags as any) {
    urls.push(aTag.href);
  }

  const result = urls.filter((url) => toLower(url).indexOf(toLower(instanceName)) !== -1);
  return result;
}

export function detectGoalIdFromUrl(url: string): number {
  const regex = /(?=modelId=)(.*?)(?=&)/g;
  const math = url.match(regex);
  if (math) {
    return Number(math.toString().replace("modelId=", ""));
  }

  // CE-18975: Check for urlencoded string
  // eg: https://nam02.safelinks.protection.outlook.com/?url=3Dhttps%3A%2F%2Fmodeglobal1
  // .executestrategy.net%2FgoalDeck%2Fgoals%3FcomponentName%3Dgoal-sidebar%26modelId%3D438%26modelType%3Dgoal (...)
  const regexUrlEncoded = /(?=modelId%3D)(.*?)(?=%26)/g;
  const mathUrlEncoded = url.match(regexUrlEncoded);
  if (mathUrlEncoded) {
    return Number(mathUrlEncoded.toString().replace("modelId%3D", ""));
  }

  return -1;
}
