import * as React from "react";
import SignInModal from "components/modals/sign-in/SignIn";
import bn from "helper/bemnames";
import { prepareAuthUri } from "helper/string";
import Loader from "components/core/loader/Loader";
import { fetchInstanceRegion } from "api/instance";
const bem = bn.create("bot-auth-popup");

interface Props {}
const AuthScreen: React.FC<Props> = () => {
  const [instanceName, setInstanceName] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const onConfirm = (): void => {
    setLoading(true);
    fetchInstanceRegion(instanceName)
      .then(() => {
        setLoading(false);
        window.location.assign(prepareAuthUri(instanceName));
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage("Instance does not exist.");
      });
  };
  return (
    <div className={bem.b()}>
      <SignInModal
        backdrop={false}
        className={bem.e("sign-in-modal")}
        open={true}
        instanceName={instanceName}
        setInstanceName={setInstanceName}
        onConfirm={onConfirm}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <Loader loading={loading} fluid />
    </div>
  );
};

export default AuthScreen;
